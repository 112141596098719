import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import Footer from "../components/footer"
import Gallery from "../components/gallery"
import Navi from "../components/navi"
import TextBlock from "../components/textBlock"
import '../styles/index.scss'
import { graphql} from "gatsby"


function Weddings({ data }) {
    return (
        <main>
            <Helmet>
            <title>Black Unicorn Wedding Photography</title>
            <meta name="title" content="Black Unicorn Wedding Photography | Hochzeitsfotografie - Portfolio Mascha und Maxim" />
            <meta name="description" content="Hochzeitsfotografie in Rastatt und Berlin. ✔individuell ✔besonders ✔qualitativ " />
            <meta name="image" content="/src/images/favicon.jpg"/>
            <meta property="og:image" content="/src/images/favicon.jpg"/>
            </Helmet>
            <Layout>
                <Navi></Navi>
                <TextBlock headline={"Mascha und Maxim"} text={"Location: Schöneberger Strand, Kiel"}></TextBlock>
                <Gallery data={data}></Gallery>
                <Footer></Footer>
            </Layout>
        </main>


    );
}

export default Weddings ;


export const query = graphql`
  query {
        portfolio: allFile( filter: { absolutePath: {regex: "./images/categories/Mascha-und-Maxim/" } }) {
            edges {
              node {
                childImageSharp {
                    fluid (maxWidth: 600){
                        src
                        srcSet
                        aspectRatio
                        sizes
                        base64
                    }
              }
            }
            }
            }
    
    
    
    
    }`